// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require('jquery')
require("channels")

require("packs/animation")
// require("packs/qqq")


$(function () {
  $('.open_pdf').on('click', function () {
    console.log('openPdf');
    console.log(window.location.origin);
    var assetPath = $('.open_pdf').data('asset-path');
    console.log(assetPath);
    var pdfPath = window.location.origin + '/' + assetPath;
    window.open(pdfPath, '_blank');
  });


  $('.burger-btn').on('click', function () {
    $(this).toggleClass('active');
    if ($(this).data('menu') === true) $('.menu').toggleClass('active');
  });

  $('.animation-arrow').on('click', function () {
    $("html, body").animate({ 'scrollTop': $('#about-me').offset().top }, 400);
  });

  $('.menu li').on('click', function () {
    $('.menu').removeClass('active');
    $('.burger-btn').removeClass('active');
  });

  document.addEventListener('scroll', onScroll, true);

  setupVideo();
  setupStickyHeader();
});


function onScroll(event) {
  var scrollPos = $(document).scrollTop() + 60;

  $('.menu a.activatable').each(function () {
    var currLink = $(this);
    var refElement = $(currLink.attr("href"));
    if (refElement.position().top <= scrollPos && refElement.position().top + refElement.height() > scrollPos) {
      $('.menu a.activatable').removeClass("active");
      currLink.addClass("active");
    }
    else {
      currLink.removeClass("active");
    }
  });
}

function setupStickyHeader() {
  var header = document.getElementById("header");
  if(header === null) {
    return;
  }

  var sticky = header.offsetTop;

  window.addEventListener("scroll", function () {
    if (header.offsetTop !== 0) {
      sticky = header.offsetTop;
    }
    if (window.pageYOffset >= sticky) {
      header.classList.add("fixed-header");
    } else {
      header.classList.remove("fixed-header");
    }
  }, true);
}

function setupVideo() {
  // $("#loadingSpin").show();
  // $('#myVideo').on('loadstart', function (event) {
  //   console.log("load start");
  // });
  // $('#myVideo').on('canplay', function (event) {
  //   $("#loadingSpin").hide();
  // });


  $(".video-play").click(function () {
    $('.cover__video').css({ "visibility": "visible", "opacity": "1" });
    $('#myVideo').get(0).play();
  });
  $(".close-video-btn").click(function () {
    $('.cover__video').css({ "visibility": "hidden", "opacity": "0" });
    var media = $('#myVideo').get(0);
    media.pause();
    media.currentTime = 0;
  });
}




//
// smooth scrolling
//
(function (document, history, location) {
  var HISTORY_SUPPORT = !!(history && history.pushState);

  var anchorScrolls = {
    ANCHOR_REGEX: /^#[^ ]+$/,
    OFFSET_HEIGHT_PX: 60,

    /**
     * Establish events, and fix initial scroll position if a hash is provided.
     */
    init: function () {
      this.scrollToCurrent();
      $(window).on('hashchange', $.proxy(this, 'scrollToCurrent'));
      $('body').on('click', 'a', $.proxy(this, 'delegateAnchors'));
    },

    /**
     * Return the offset amount to deduct from the normal scroll position.
     * Modify as appropriate to allow for dynamic calculations
     */
    getFixedOffset: function () {
      return this.OFFSET_HEIGHT_PX;
    },

    /**
     * If the provided href is an anchor which resolves to an element on the
     * page, scroll to it.
     * @param  {String} href
     * @return {Boolean} - Was the href an anchor.
     */
    scrollIfAnchor: function (href, pushToHistory) {
      var match, anchorOffset;

      if (!this.ANCHOR_REGEX.test(href)) {
        return false;
      }

      match = document.getElementById(href.slice(1));

      if (match) {
        anchorOffset = $(match).offset().top - (match.id === 'about-me' ? 0 : this.getFixedOffset()) ;
        $('html, body').animate({ scrollTop: anchorOffset });

        // Add the state to history as-per normal anchor links
        if (HISTORY_SUPPORT && pushToHistory) {
          history.pushState({}, document.title, location.pathname + href);
        }
      }

      return !!match;
    },

    /**
     * Attempt to scroll to the current location's hash.
     */
    scrollToCurrent: function (e) {
      if (this.scrollIfAnchor(window.location.hash) && e) {
        e.preventDefault();
      }
    },

    /**
     * If the click event's target was an anchor, fix the scroll position.
     */
    delegateAnchors: function (e) {
      var elem = e.target;

      if (this.scrollIfAnchor(elem.getAttribute('href'), true)) {
        e.preventDefault();
      }
    }
  };

  $(document).ready($.proxy(anchorScrolls, 'init'));
})(window.document, window.history, window.location);



// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

