$(function () {
    $(".arrow-container").click(function (e) {
        e.target.animate([
            { left: '0' },
            { left: $(e.target).hasClass('left-arrow-container') ? '-5px' : '5px' },
            { left: '0' }
        ], {
            duration: 500,
            iterations: 2
        });
        e.preventDefault();
    });
});
